import HeroTextBackground from "../../assets/svg/global/HeroTextBackground.svg";
import {Typography} from "@material-tailwind/react";

const Hero = () => {
  return (
    <div className="w-full mx-auto flex justify-center mb-24">
      <div className="max-w-7xl w-full h-[500px] flex items-center justify-center rounded-[30px] overflow-hidden"
           style={{
             backgroundImage: `url(${HeroTextBackground})`,
             backgroundRepeat: "no-repeat",
             backgroundSize: "cover",
           }}
      >
        <Typography
          variant="paragraph"
          className="text-center font-clash tracking-two leading-[68px] font-semibold text-[68px] text-[#F6F7F8]">
          Terms of Use
        </Typography>
      </div>
    </div>
  );
};

export default Hero;
