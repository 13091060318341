import {Fragment} from "react";
import {Typography} from "@material-tailwind/react";
import BonusRightRing from '../../assets/svg/homepage/Bonus-Right-Ring.svg'
import BonusBottomRing from '../../assets/svg/homepage/Bonus-Bottom-Ring.svg'
import BonusView from '../../assets/img/homepage/BonusView.png'

const Bonus = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-full max-w-7xl overflow-hidden min-h-[440px] h-[500px] rounded-[30px] flex gap-24 items-start justify-start my-20 bg-[#E7F6EA] border border-[#12A6284D]">
          <div className="flex h-full w-7/12">
            <div className="h-[133px] mb-auto">
              <img src={BonusRightRing} alt={BonusRightRing} className="w-full h-full"/>
            </div>

            <div className="max-w-[396px] mt-[140px] gap-8">
              <Typography className="font-clash font-semibold text-4xl leading-10 tracking-two text-textPrimary" variant="h2">
                Earn up to ₦500 as a welcome sign-up bonus bonus!
              </Typography>

              <Typography className="text-[#53557A] font-normal font-clash text-base leading-[29.52px] tracking-two text-justify">
                Plus, enjoy instant cashback on your Cowry card every time you top up. It’s fast, easy, and the rewards keep coming.
              </Typography>
            </div>

            <div className="mt-auto h-[133px]">
              <img src={BonusBottomRing} alt={BonusBottomRing} className="w-full h-full"/>
            </div>
          </div>

          <div className="h-full w-[400px] mt-10">
            <img src={BonusView} alt={BonusView}/>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Bonus;
