import {Fragment} from "react";
import {Card, Typography} from "@material-tailwind/react";
import GetStartedBG from "../../assets/svg/homepage/GetStarted.svg";

const GetStarted = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-full flex flex-col gap-4 items-center justify-center my-20 max-w-7xl">
          <Typography variant="h2" className="text-textPrimary tracking-two font-semibold text-[40px] text-center font-clash">
            How to get started
          </Typography>

          <Card
            className={`rounded-[30px] w-full bg-[#EAEAEE] my-8 min-h-[650px] bg-no-repeat bg-center p-10 shadow-none`}
            style={{
              backgroundImage: `url(${GetStartedBG})`
              // backgroundImage: `url(${OurSolutionsTrain})`,
            }}
          >
            <div className="w-full h-full flex flex-col justify-center items-start min-h-[650px]">
              <Typography variant="h5"
                          className="text-4xl font-semibold text-textPrimary font-clash">Search</Typography>

              <Typography
                variant="h5"
                className="text-lg tracking-two font-normal text-[#53557A] font-clash max-w-[340px] flex-wrap"
              >
                Enter your destination in the 'Where to?' field. Your current location will be selected by default, but
                you can edit it if needed.
              </Typography>
            </div>
          </Card>
        </div>
      </div>
    </Fragment>
  )
}

export default GetStarted;
