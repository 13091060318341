import {Fragment} from "react";
import {Typography} from "@material-tailwind/react";
import LagosBlueLine from "../../assets/img/homepage/Lagos-Blue-Line.png";
import LagosRedLine from "../../assets/img/homepage/Lagos-Red-Line.png";
import LagosFerry from "../../assets/img/homepage/Lagos-Ferry.png";
import LagosBRTNetwork from "../../assets/img/homepage/Lagos-BRT-Network.png";

const OurSolutions = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-full flex flex-col items-center gap-4 my-20 max-w-7xl">
          <div className="flex flex-col w-75 max-w-[762px] gap-4 items-center">
            <Typography
              variant="h5"
              className="font-semibold font-clash tracking-two text-textPrimary leading-[40px] text-[40px]"
            >
              Urban Mobilities we provide solutions to
            </Typography>

            <Typography
              variant="h5"
              className="font-normal font-clash text-[#53557A] tracking-two leading-[29.52px] text-[24px] text-center"
            >
              OrnaMap simplifies your city exploration. Easily switch between trains, buses, and ferries with a tap.
            </Typography>
          </div>

          {/*<div*/}
          {/*  className="w-full h-[652px] bg-cover bg-center bg-no-repeat overflow-hidden rounded-3xl my-4"*/}
          {/*  style={{*/}
          {/*    backgroundImage: `url(${OurSolutionsTrain})`,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div className="flex items-center justify-end h-full mt-4">*/}
          {/*    <div className="h-full p-12 flex flex-col items-end w-[60%]">*/}
          {/*      <Typography*/}
          {/*        variant="h4"*/}
          {/*        className="text-[32px] font-semibold text-textPrimary w-full text-center"*/}
          {/*      >*/}
          {/*        Train*/}
          {/*      </Typography>*/}
          {/*      <Typography*/}
          {/*        variant="paragraph"*/}
          {/*        className="mt-4 font-[400] text-[#53557A] text-[24px] text-center font-clash"*/}
          {/*      >*/}
          {/*        OrnaMap helps you track live train times and plan your perfect route in seconds. See real-time*/}
          {/*        arrivals, platform changes, and service updates for a stress-free journey.*/}
          {/*      </Typography>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="my-4 w-full grid grid-cols-1 gap-6 lg:grid-cols-2">
            {/* Lagos Blue Line Rail */}
            <div
              className="w-full h-[604px] overflow-hidden rounded-[20px] border border-[#75757A33] bg-white justify-start items-center flex flex-col gap-6"
            >

              <div className="w-full h-[380px]">
                <img width="100%" height="100%" src={LagosBlueLine} alt={LagosBlueLine} className=""/>
              </div>


              <div className="max-w-[528px] gap-3 mt-6">
                <Typography
                  variant="h3"
                  className="text-[32px] font-medium font-clash tracking-two leading-[32px] text-textPrimary w-full text-center"
                >
                  Lagos Blue Line Rail (Mile2 - Marina)
                </Typography>
                <Typography
                  variant="paragraph"
                  className="mt-4 font-clash font-normal leading-[29.52px] tracking-two text-[#53557A] text-[24px] text-center"
                >
                  Get real time train schedules, train fares, accurate train stops, easy navigation to train stations.
                </Typography>
              </div>
            </div>

            {/*Lagos Red Line Rail*/}
            <div
              className="w-full h-[604px] overflow-hidden rounded-[20px] border border-[#75757A33] bg-white justify-start items-center flex flex-col gap-6">

              <div className="w-full h-[380px]">
                <img width="100%" height="100%" src={LagosRedLine} alt={LagosRedLine} className=""/>
              </div>

              <div className="max-w-[574px] gap-3 mt-6">
                <Typography
                  variant="h3"
                  className="text-[32px] font-medium font-clash tracking-two leading-[32px] text-textPrimary w-full text-center"
                >
                  Lagos Red Line Rail (Agbado -Oyingbo)
                </Typography>
                <Typography
                  variant="paragraph"
                  className="mt-4 font-clash font-normal leading-[29.52px] tracking-two text-[#53557A] text-[24px] text-center"
                >
                  Move around Lagos Mainland with ease. Plan your trips effortlessly; check out train timetable, ticket
                  fares and train stops with OrnaMap
                </Typography>
              </div>
            </div>

            {/*Lagos Ferry*/}
            <div
              className="w-full h-[604px] overflow-hidden rounded-[20px] border border-[#75757A33] bg-white justify-start items-center flex flex-col gap-6"
            >
              <div className="w-full h-[380px] max-h-[400px]">
                <img width="100%" height="100%" src={LagosFerry} alt={LagosFerry} className="max-h-[400px]"/>
              </div>

              <div className="max-w-[586px] gap-3 mt-6">
                <Typography
                  variant="h3"
                  className="text-[32px] font-medium font-clash tracking-two leading-[32px] text-textPrimary w-full text-center"
                >
                  Lagos Ferry
                </Typography>
                <Typography
                  variant="paragraph"
                  className="mt-4 font-clash font-normal leading-[29.52px] tracking-two text-[#53557A] text-[24px] text-center"
                >
                  OrnaMap provides all the details you need for your water-way trips; from real-time ferry schedules ,
                  fares, to terminal locations. OrnaMap has you covered.
                </Typography>
              </div>
            </div>

            {/*Lagos BRT Network*/}
            <div
              className="w-full h-[604px] overflow-hidden rounded-[20px] border border-[#75757A33] bg-white justify-start items-center flex flex-col gap-6"
            >
              <div className="w-full h-[380px] max-h-[400px]">
                <img width="100%" height="100%" src={LagosBRTNetwork} alt={LagosBRTNetwork} className="max-h-[400px]"/>
              </div>

              <div className="max-w-[586px] gap-3 mt-6">
                <Typography
                  variant="h3"
                  className="text-[32px] font-medium font-clash tracking-two leading-[32px] text-textPrimary w-full text-center"
                >
                  Lagos BRT Network
                </Typography>

                <Typography
                  variant="paragraph"
                  className="mt-4 font-clash font-normal leading-[29.52px] tracking-two text-[#53557A] text-[24px] text-center"
                >
                  Get bus stop to bus stop navigation in Lagos. And find the the fastest and cheapest BRT route to your destination.
                </Typography>
              </div>
            </div>

          </div>

        </div>
      </div>
    </Fragment>
  );
}

export default OurSolutions;
