import { ReactNode } from "react";
import Header from "./components/global/header";
import Footer from "./components/global/Footer";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    // <div className="w-screen h-full bg-[#F6F7F8]">
    // <div className="w-screen h-full bg-[#f6f7f8]">
    <div className="w-screen h-screen !bg-[#F6F7F8]">
      <Header/>

      <main className="w-full mx-auto mt-10 bg-[#F6F7F8]">
        {children}
      </main>

      <Footer/>
    </div>
  )
}

export default Layout;
