import {Fragment} from "react";
import {Link} from "react-router-dom";
import {Typography} from "@material-tailwind/react";
import {Calendar} from "lucide-react";

const Info = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center mt-24">
        <div className="w-full max-w-7xl grid grid-cols-1 items-center gap-24 lg:grid-cols-[30%_70%]">
          <div className="h-full w-full flex items-baseline sticky">
            <div className="flex w-full flex-col gap-8 pl-4 py-6 pr-7 bg-[#EAEAEE] sticky top-0">
              <Link to="#data-usage"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">What data do we retain and for what purpose?</Link>

              <Link to="#info-usage"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">A summary of wow your information is utilized</Link>

              <Link to="#third-party-usage"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">What third parties receive your information?</Link>

              <Link to="#legal-usage"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">On what legal grounds do we process your information?</Link>

              <Link to="#personal-usage"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">Your rights regarding your personal data in our possession                Notes</Link>

              <Link to="#security-usage"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">Our measures to ensure the security of your information</Link>

              <Link to="#duration"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">The duration for which we retain your information</Link>

              <Link to="#location"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">Which locations are your personal information stored</Link>

              <Link to="#children-info"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">Do you collect children's information?</Link>

              <Link to="#revision"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">How will you be informed of revisions to our privacy policy?</Link>

              <Link to="#complaint"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">What if you wish to file a complaint?</Link>

              <Link to="#reach-us"
                    className="font-clash font-medium text-[16px] leading-[16px] tracking-two text-[#53557A]">How to reach out to us</Link>
            </div>
          </div>

          <div className="w-full h-full flex flex-col items-baseline gap-8">
            {/*Privacy Policy*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#privacy-policy">
              <Typography variant="h2"
                          className="font-clash font-semibold leading-[40px] tracking-two text-[#080912] text-[40px]">
                Privacy Policy
              </Typography>

              <div className="flex gap-1 items-center mb-3">
                <Calendar className="h-6 w-6 text-[#53557A]"/>

                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-4 text-[#53557A]"
                >
                  Last modified: April 21, 2022
                </Typography>
              </div>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                Effective as of September 7th, 2023, this privacy policy outlines our data collection practices, the
                reasons behind them, and how we utilize the information. It pertains to all individuals who utilize our
                application, known as "OrnaMap."
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We are ORNAMAP TECHNOLOGIES, identified as "we," "our," or "us," and we are a registered business in
                Nigeria, holding BN Number 7088839 with the Nigeria Corporate Affairs Commission. Should you have any
                inquiries regarding this policy, please reach out to us via the App or send an email to <Link
                to="mailto:support@ornamap.com" target="_blank"
                className="font-clash hover:text-[#282B59]">support@ornamap.com</Link>.
              </Typography>
            </div>

            {/*What data do we retain and for what purpose?*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#data-usage">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                What data do we retain and for what purpose
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We collect information from and about users of the App directly from you when you provide information to
                us and automatically when you use the App.
              </Typography>
            </div>

            {/*Information You Provide*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#information-provided">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Information You Provide
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                When you utilize our App, you have the opportunity to supply us with two types of information
              </Typography>

              {/*Details regarding your places and preferences*/}
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-[26.88px] text-[#2D2D2D]"
                >
                  (i.) Details regarding your places and preferences
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  You can choose to share information about your preferred locations, such as Home, Work, and other
                  travel preferences. We utilize this data to enhance personalized journey-planning services and also
                  analyze it collectively to improve the App. For example, we may prioritize certain journey results
                  over others based on this data.
                </Typography>
              </div>

              {/*Direct communications*/}
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-[26.88px] text-[#2D2D2D]"
                >
                  (ii.) Direct communications
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  When you engage in communication with us, such as reporting problems or raising queries, we may receive information about the type of device you are using during the interaction. This information is instrumental in addressing issues within the App.
                </Typography>
              </div>
            </div>

            {/*Information Created When You Use Our App*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#information-created">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Information Created When You Use Our App
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                The following outlines the types of information that are generated when you use our App. We associate
                this information with a randomly generated installation ID number, which changes each time you install
                the OrnaMap App. This installation ID is used to pseudonymize the data we collect from you, and we do
                not access any permanent device ID numbers.
              </Typography>

              {/*Location Information*/}
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-[26.88px] text-[#2D2D2D]"
                >
                  (i.) Location Information
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  We employ the location data described in the "Location Information" section to enhance the App's
                  results and for the purposes detailed below. If you have enabled your device's location services as an
                  OrnaMap App user, we may collect and utilize your starting location information when you launch the
                  App. If you disable location services on your device, we will only collect the starting and ending
                  points you manually search for.
                </Typography>
              </div>

              {/*Device Information*/}
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-[26.88px] text-[#2D2D2D]"
                >
                  (ii.) Device Information
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  We gather information about the devices you use to access our App, including the type of computer or device, the App's installation ID on your device, hardware model, operating system and versions, software, file names and versions, preferred language, time zone settings, and device motion data. This information is essential for diagnosing bugs and enhancing the App. Importantly, we do not collect any permanent device ID numbers.
                </Typography>
              </div>

              {/*Log and Usage Information*/}
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-[26.88px] text-[#2D2D2D]"
                >
                  (iii.) Log and Usage Information
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  Upon your initial use of the app, we collect your location, and during subsequent interactions, we gather data on how and when you use it. This includes details such as the journey results you select, pages visited, instances of app crashes, and other system activity. We utilize this information to improve our App in various ways, including bug fixes, crash prevention, and determining which features our users find most valuable.
                </Typography>
              </div>
            </div>

            {/*A summary of how your information is utilized*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#info-usage">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                A Summary of How Your Information Is Utilized
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                In summary, the information we gather serves the following purposes:
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                1. Providing and Enhancing Our App: We utilize collected data to deliver and enhance the functionality
                of our App.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                2. Creating User Profiles: We construct profiles based on your preferences to personalize the OrnaMap
                App and furnish you with pertinent transportation information.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                3. Transportation Recommendations: We suggest transport options aligned with your stated preferences and
                previous App usage.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                4. Customer Support: We use the information to respond to your emails and address inquiries related to
                your App usage.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                5. Marketing Communications: We may send you marketing communications through email or in-App
                notifications, keeping you informed about our policies, terms, and new city launches.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                6. Issue Identification: We identify and address operational issues within the App, including providing
                crash reports to resolve performance concerns.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                7. App Enhancement: We continuously work on improving the App's design, functionality, and content,
                customizing them to better suit your needs.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                8. User Transport Research: We conduct research on users' transportation preferences to enhance the App
                further.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We are committed to enhancing the App by adding new features and functionality to enhance safety,
                enjoyment, and utility. These improvements may involve data utilization similar to the purposes outlined
                above. Our privacy policy is regularly reviewed and will be updated to reflect any changes in our data
                usage practices.
              </Typography>
            </div>

            {/*What third parties receive your information?*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#third-party-usage">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                what third parties receive your information?
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                Occasionally, we may share information with third parties to enhance the App and improve the user
                experience. We make every effort to pseudonymize the data (remove personally identifiable information)
                and minimize the information sent to these third parties.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                1. Search Partners: We collaborate with search partners such as Google to help you discover desired
                destinations within the App. These partners receive the names of the places you search for, and if you
                have enabled location services on your device, they also receive your current location. This is done to
                provide you with the most relevant search results.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                2. Analytics Vendors: We employ third-party vendors like Google Analytics to gain insights into user
                interactions within the App. These vendors receive information indicating that someone has interacted
                with specific features in the App. They organize and provide this data to us to aid in making
                enhancements to the App. Importantly, this information does not contain personally identifiable details.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                3. Crash/Error Reporting Vendors: For reporting and addressing crashes experienced while using the App,
                we utilize Crashlytics. This helps us identify and rectify issues with the App to ensure a smoother
                experience for you.
              </Typography>
            </div>

            {/*On What Legal Grounds Do We Process Your Information?*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#legal-usage">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                On What Legal Grounds Do We Process Your Information?
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We will only handle your personal information when we have a lawful justification to do so. Our legal
                grounds for processing your personal data encompass the following
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                1. Legitimate Interests: We may process your data when it is necessary to serve our legitimate
                interests, which include activities such as researching, developing, enhancing, and delivering new and
                personalized features for the App. Additionally, we use this basis to provide support to users during
                their trips and to ensure the security of the App for all users. Furthermore, it allows us to take
                measures to prevent violations of our terms and policies, as well as to prevent harmful or illegal
                activities.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                2. Legal and Regulatory Compliance: We may process your data when it is essential to adhere to
                applicable legal and regulatory obligations. This ensures that our data processing activities align with
                the requirements set forth by relevant laws and regulations.
              </Typography>
            </div>

            {/*Your Rights Regarding Your Personal Data in Our Possession*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#personal-usage">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Your Rights Regarding Your Personal Data in Our Possession
              </Typography>

              {/*Access*/}
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-[26.88px] text-[#2D2D2D]"
                >
                  (i.) Access
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  You have the legal right to request access to a copy of the personal information we have on record for
                  you. To do so, please contact us at support@ornamap.com. To provide you with your information, it's
                  necessary for you to have created an account, as this enables us to link your Installation ID to a
                  verifiable email address. You should have access to the email address associated with your account so
                  that we can confirm your identity.
                </Typography>
              </div>

              {/*Deletion*/}
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-[26.88px] text-[#2D2D2D]"
                >
                  (ii.) Deletion
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  We retain your personal information for as long as it's required to provide our services and products.
                  You possess the right to request the deletion of your personal information under certain circumstances.
                  These circumstances may include when you believe we no longer need the data for its original purposes,
                  you wish to withdraw your consent (provided there is no other lawful basis for data use), or if we are
                  not using your information in a lawful manner. Please note that for legal reasons, we may not always be
                  able to fulfil deletion requests. You can initiate a request for the deletion of your personal
                  information by emailing us at <Link to="mailto:support@ornamap.com" target="_blank" className="font-clash hover:text-[#282B59]">support@ornamap.com</Link>.
                </Typography>
              </div>

              {/*Notifications*/}
              <div className="flex flex-col gap-1">
                <Typography
                  variant="h6"
                  className="font-clash font-medium tracking-two text-base leading-[26.88px] text-[#2D2D2D]"
                >
                  (iii.) Notifications
                </Typography>

                <Typography
                  variant="paragraph"
                  className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
                >
                  We may send you information that we believe could be of interest to you (e.g., notifications about train
                  and ferry disruptions relevant to your location), or information you've specifically requested from us
                  via push notifications and/or email (if provided). If you prefer not to receive further notifications or
                  emails, you have the option to opt-out.
                </Typography>
              </div>
            </div>

            {/*Our Measures to Ensure the Security of Your Information*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#security-usage">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Our Measures to Ensure the Security of Your Information
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We prioritize the security of all user-associated information. To safeguard this data, we implement
                various protective measures, including encryption, access controls for information, and firewalls.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                However, it's important to be aware that transmitting information over the internet and mobile networks
                is never entirely foolproof. We cannot provide an absolute guarantee of the security of data you submit
                through the App while it is in transit over the internet or mobile networks. Therefore, any such data
                submission carries an inherent level of risk and is undertaken at your discretion.
              </Typography>
            </div>

            {/*The Duration for Which We Retain Your Information*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#duration">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                The Duration for Which We Retain Your Information
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                Through the provision of products or services, we generate records that encompass your information,
                including customer account records and activity records. We diligently maintain these records to
                facilitate our ability to serve our users effectively and to adhere to legal and regulatory obligations.
                These records are invaluable in demonstrating our commitment to fulfilling our responsibilities and in
                providing evidence of our business activities.
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                The duration for which we retain records is contingent on factors such as the type of record, the
                characteristics of the associated activity, product, or service, as well as the pertinent legal and
                regulatory prerequisites. The duration of information retention may be subject to change based on
                evolving business needs or modifications to legal and regulatory requirements.
              </Typography>
            </div>

            {/*Which Locations Are Your Personal Information Stored*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#location">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Which locations are your personal information stored
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                The information you provide through the App is transmitted to and securely stored on servers located in
                both Ireland and the United States. It's important to note that information you submit may also be
                transferred by us to our other offices and/or shared with third parties as described in the
                aforementioned situations. These third parties may be located outside the European Economic Area,
                including locations such as the United States.
              </Typography>
            </div>

            {/*Do You Collect Children's Information?*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#children-info">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                Do you collect children's information?
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We do not intentionally gather personal information from individuals under the age of 13. If you are
                under 13 years old, kindly refrain from submitting any personal information through the App or email. We
                encourage parents and legal guardians to supervise their children's internet activities and to support
                the enforcement of our privacy policy by advising their children not to disclose personal information on
                our website without their consent. If you have reason to believe that a child under the age of 13 has
                furnished personal information to us, please get in touch with us using the contact details provided
                below, and we will take the necessary steps to remove that information from our databases.
              </Typography>
            </div>

            {/*How Will You Be Informed of Revisions to Our Privacy Policy?*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#revision">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                How Will You Be Informed of Revisions to Our Privacy Policy?
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We may periodically update this privacy policy. Any modifications or additions to this privacy policy
                will be made available within the App and become effective on the date of their posting. We encourage
                you to periodically review this privacy notice to ascertain whether any changes have been made to how we
                handle your personal data. The most recent update to this privacy policy was implemented on September 7,
                2023.
              </Typography>
            </div>

            {/*What If You Wish to File a Complaint?*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#complaint">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                What If You Wish to File a Complaint?
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                We are committed to responsibly managing your information. If you have concerns or are dissatisfied with
                the way we handle your data, please don't hesitate to contact our Data Protection Officer via email at
                support@ornamap.com. Your feedback is important to us, and we will work diligently to address any
                concerns you may have.
              </Typography>
            </div>

            {/*How to Reach Out to Us*/}
            <div className="w-full h-auto flex flex-col gap-5" id="#reach-us">
              <Typography
                variant="h4"
                className="font-clash font-medium tracking-two text-2xl leading-6 text-[#2D2D2D]"
              >
                How to Reach Out to Us
              </Typography>

              <Typography
                variant="paragraph"
                className="font-clash tracking-two text-base font-normal text-[#2D2D2D] leading-[26.88px] max-w-[744px]"
              >
                If you have any questions, concerns, or comments regarding this privacy policy or if you wish to make
                any requests related to your personal data, please feel free to submit them via email to our Data
                Protection Officer at <Link to="mailto:support@ornamap.com" target="_blank" className="font-clash hover:text-[#282B59]">support@ornamap.com</Link>.. We are here to assist you with any inquiries or requests you
                may have.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Info;
