import { parsePhoneNumber } from "awesome-phonenumber";

export const formatPhoneNumber = (
  phone: number,
  regionCode?: string,
) => {
  return parsePhoneNumber(phone.toString(), {
    regionCode: regionCode ?? 'NG',
  }).number?.national;
};

export const handleDownloadNowClick = () => {
  let url = '';

  const userAgent = navigator.userAgent;
  const redirectToDownloadLocation = () => {
    if (/android/i.test(userAgent)) {
      url = "https://play.google.com/store/apps/details?id=com.ornamap.android";
    } else if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !((window as any).MSStream)) {
      url = "https://apps.apple.com/ng/app/ornamap/id6450780439";
    } else {
      url = `${window.location.origin}/download`;
    }
  }

  redirectToDownloadLocation()
  window.open(url, '_blank');
}