import {Fragment} from "react";
import UpdateButton from "../components/update/UpdateButton";
import Text from "../components/global/Text";

interface UpdateButtonProps {
  to: string;
  image: string;
  header: string;
  message: string;
}

const AvailableUpdates = () => {

  const updates: UpdateButtonProps[] = [
    // {
    //   to: `http://lamata-ng.com/rail/`,
    //   image: TrainImage,
    //   header: `Blue Line`,
    //   message: `Lagos blue line is live`
    // },
    // {
    //   to: `http://lamata-ng.com/rail/`,
    //   image: TrainImage,
    //   header: `Blue Line`,
    //   message: `Lagos blue line is live`
    // },
    // {
    //   to: `http://lamata-ng.com/rail/`,
    //   image: TrainImage,
    //   header: `Blue Line`,
    //   message: `Lagos blue line is live`
    // },
    // {
    //   to: `http://lamata-ng.com/rail/`,
    //   image: TrainImage,
    //   header: `Blue Line`,
    //   message: `Lagos blue line is live`
    // },
    // {
    //   to: `http://lamata-ng.com/rail/`,
    //   image: TrainImage,
    //   header: `Blue Line`,
    //   message: `Lagos blue line is live`
    // }
  ];

  return (
    <Fragment>
      <div className={`w-full flex items-center flex-col justify-center h-screen`}>
        {/*<div className="w-full h-full max-w-md bg-[#282B59] flex items-center px-2" style={{*/}
        {/*  height: "80px"*/}
        {/*}}>*/}
        {/*  <div className={`w-[30px] h-[30px] rounded-full bg-white flex items-center justify-center`}>*/}
        {/*    <img src={LeftArrow} width={20} height={20} alt={LeftArrow} />*/}
        {/*  </div>*/}

        {/*  <div className={`w-[90%] flex justify-center`}>*/}
        {/*    <Text text={`Update`} weight={700} className={`!text-[20px] text-white !leading-0 -tracking-[0.8px]`}/>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className={`w-full h-full max-w-md bg-white flex flex-col ${updates.length === 0 ? 'justify-center items-center' : ''} p-2 mb-4`}>
          {updates.length > 0 ? (
            <>
              {updates.map((item) => {
                return <UpdateButton to={item.to} header={item.header} image={item.image} message={item.message}/>
              })}
            </>
          ) : (
            <div className="flex flex-col w-full items-center justify-center px-3">
              <Text
                text={`No Update Available Yet`}
                weight={700}
                className={`!text-[24px] text-primary leading-normal tracking-normal`}
              />

              <Text
                text={`Travel Information, Updates, and Events will appear here.`}
                weight={500}
                className={`!text-[16px] text-primary text-center leading-normal -tracking-[0.64px]`}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default AvailableUpdates;