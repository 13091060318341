import Hero from "../components/privacy/hero";
import Info from "../components/privacy/info";
import YourExperience from "../components/home/YourExperience";
import Layout from "../Layout";




const PrivacyNew = () => {
  return (
    <Layout>
      <Hero/>

      <Info/>

      <YourExperience/>
    </Layout>
  );
};

export default PrivacyNew;

