import { Minus, Plus } from "lucide-react";
import {Typography} from "@material-tailwind/react";

interface FaqItemProps {
  question: string;
  answer: string;
  isOpen: boolean;
  onToggle: () => void;
}

const FaqItem = ({ question, answer, isOpen, onToggle }: FaqItemProps) => {
  return (
    <div className="border-b border-gray-500 last:border-b-0">
      <button
        onClick={onToggle}
        className={`w-full flex items-center justify-between p-4 text-left hover:cursor-pointer transition-colors ${!isOpen ? "my-[30px]" : ""}`}
      >
        <div className="flex items-center gap-3">
          <div className="flex-shrink-0">
            {isOpen ? (
              <Minus className="w-6 h-6 text-[#080912]" />
            ) : (
              <Plus className="w-6 h-6 text-[#080912]" />
            )}
          </div>
          <Typography className="font-medium text-2xl leading-[24px] tracking-two font-clash text-black">{question}</Typography>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0  ml-14">
          <Typography className="text-[#53557A] font-clash font-normal leading-[30.24px] tracking-two text-lg">{answer}</Typography>
        </div>
      )}
    </div>
  );
};

export default FaqItem;