// @ts-ignore
import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, MobileNav, Navbar, Typography, IconButton } from '@material-tailwind/react'
import NewOrnaMapLogo from "../../assets/svg/homepage/New-Logo.svg"
import AppleLogo from "../../assets/svg/homepage/Apple-Logo.svg"
import PlayStoreLogo from "../../assets/svg/homepage/PlayStore-Logo.svg"
import {handleDownloadNowClick} from "../../utilities";

const Header = () => {
  const [openNav, setOpenNav] = useState(false);
  // const [openSchedule, setOpenSchedule] = useState(0);

  // const handleOpenSchedule = (value: number) => {
  //   setOpenSchedule(openSchedule === value ? 0 : value);
  // };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  const navList = (
    <Fragment>
      <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
        {/*Home*/}
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
          <Link to="/" className="text-blue-gray-900">Home</Link>
        </Typography>

        {/*Blog*/}
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
          <Link to="/https://blog.ornamap.com/" className="text-blue-gray-900">Blog</Link>
        </Typography>

        {/*<Typography*/}
        {/*  as="li"*/}
        {/*  variant="small"*/}
        {/*  color="blue-gray"*/}
        {/*  className="p-1 font-normal"*/}
        {/*>*/}
        {/*  <Link to="/schedules" className="text-blue-gray-900">Schedule</Link>*/}
        {/*</Typography>*/}

        {/*FAQs*/}
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
          <Link to="/faqs" className="text-blue-gray-900">FAQs</Link>
        </Typography>
      </ul>
    </Fragment>
  )

  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center">
        <Navbar className="sticky bg-transparent border-none shadow-none top-0 z-10 h-max max-w-6xl rounded-none px-4 py-2 lg:px-8 lg:py-4">
          <div className="flex items-center justify-between text-blue-gray-900 max-w-7xl mx-auto">
            <img src={NewOrnaMapLogo} alt={NewOrnaMapLogo} height={40}/>

            <div className="mr-4 hidden lg:block">{navList}</div>

            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-x-1">
                <Button
                  size="sm"
                  className="hidden bg-[#282B59] rounded-full p-4 text-white normal-case lg:flex lg:items-center"
                  onClick={handleDownloadNowClick}
                >
                  <div className="flex items-center mr-2 gap-2">
                    <img src={AppleLogo} alt={AppleLogo} height={20} />
                    <div className="min-h-[20px] border-l border-white w-[1px]"/>
                    <img src={PlayStoreLogo} alt={PlayStoreLogo} height={20} />
                  </div>
                  <span>Download App Now</span>
                </Button>
              </div>

              <IconButton
                variant="text"
                className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                ripple={false}
                onClick={() => setOpenNav(!openNav)}
              >
                {openNav ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    className="h-6 w-6"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </IconButton>
            </div>
          </div>

          <MobileNav open={openNav}>
            {navList}
            <div className="flex items-center justify-center gap-x-1 w-full">
              <Button
                className="bg-[#282B59] rounded-full p-4 text-white normal-case justify-center flex items-center text-lg"
                fullWidth
                onClick={handleDownloadNowClick}
              >
                <div className="flex items-center mr-2 gap-2">
                  <img src={AppleLogo} alt={AppleLogo} height={40} />
                  <div className="min-h-[20px] border-l border-white w-[1px]"/>
                  <img src={PlayStoreLogo} alt={PlayStoreLogo} height={40} />
                </div>
                <span>Download App Now</span>
              </Button>
            </div>
          </MobileNav>
        </Navbar>
      </div>
    </Fragment>
  )
}

export default Header