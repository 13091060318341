import { Fragment } from "react";
import OrnaMapLogo from "../../assets/img/global/logo-white-tint.svg"
import {Button, Typography} from "@material-tailwind/react";
import AppleLogo from "../../assets/svg/homepage/Apple-Logo.svg";
import PlayStoreLogo from "../../assets/svg/homepage/PlayStore-Logo.svg";
import TwitterIcon from "../../assets/svg/global/Twitter.svg";
import TikTok from "../../assets/svg/global/Tiktok.svg"
import Facebook from "../../assets/svg/global/Facebook.svg"
import LinkedIn from "../../assets/svg/global/LinkedIn.svg"
import Instagram from "../../assets/svg/global/Instagram.svg"
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <Fragment>
      <div className="w-full bg-[#282B59] h-[792px] flex flex-col justify-center pt-16 items-center">
        <div className="w-full h-full max-w-7xl flex flex-col items-baseline justify-start gap-16">
          <div className="flex justify-between gap-24">
            {/*OrnaMap*/}
            <div className="w-full flex flex-col gap-3">
              <div className="flex gap-2">
                <div className="w-[30.5px] h-[31.3px]">
                  <img src={OrnaMapLogo} alt={OrnaMapLogo}/>
                </div>
                <Typography
                  className="font-clash text-2xl leading-[29.2px] tracking-four text-[#EAEAEE] font-semibold">OrnaMap</Typography>
              </div>

              <Typography
                className="font-clash font-normal text-sm leading-[17.22px] tracking-two text-[#EAEAEE] max-w-[153px]">Never
                Miss a Beat. Own Every Commute.</Typography>
            </div>

            {/*Company*/}
            <div className="w-full flex flex-col justify-start items-baseline">
              <Typography
                className="font-clash font-medium text-base leading tracking-two text-[#EAEAEE]">Company</Typography>

              <div className="flex flex-col mt-6 gap-4">
                <Link to="/about" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">About Us</Link>

                <Link to="https://blog.ornamap.com/" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">Blog</Link>

                <Link to="#" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">FAQs</Link>
              </div>
            </div>

            {/*Legal*/}
            <div className="w-full flex flex-col justify-start items-baseline">
              <Typography
                className="font-clash font-medium text-base leading tracking-two text-[#EAEAEE]">Legal</Typography>

              <div className="flex flex-col mt-6 gap-4">
                <Link to="/privacy-policy" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">Privacy Policy</Link>

                <Link to="/terms" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">Terms & Condition</Link>
              </div>
            </div>

            {/*Contact Us*/}
            <div className="w-full flex flex-col justify-start items-baseline">
              <Typography
                className="font-clash font-medium text-base leading tracking-two text-[#EAEAEE]">Contact Us</Typography>

              <div className="flex flex-col mt-6 gap-4">
                <Link to="tel:+2347051334957" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">+234 705 133 4957</Link>

                <Link to="mailto:support@ornamap.com" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">support@ornamap.com</Link>

                <Link to="https://www.google.com/maps/place/3+Folorunsho+Kazeem+Cl,+Ikosi+Ketu,+Lagos+105102,+Lagos" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">3, Folorusho Kazeem Close, Lagos Nigeria</Link>
              </div>
            </div>

            {/*Download*/}
            <div className="flex flex-col gap-5">
              {/*IOS*/}
              <Link to="https://apps.apple.com/ng/app/ornamap/id6450780439" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">
                <Button
                  size="sm"
                  className="bg-transparent gap-2 rounded-full justify-center py-4 px-[41.5px] text-white normal-case flex lg:items-center w-[242px] border hover:cursor-pointer border-[#3E406A] hover:bg-[#3E406A] hover:border-none"
                >
                  <img src={AppleLogo} alt={AppleLogo} height={20} className=""/>
                  <span>Download for IOS</span>
                </Button>
              </Link>

              {/*Android*/}
              <Link to="https://play.google.com/store/apps/details?id=com.ornamap.android" target="_blank" className="font-clash font-normal text-base leading-[19.68px] tracking-two text-[#EAEAEE]">
                <Button
                  size="sm"
                  className="bg-transparent gap-2 rounded-full justify-center py-4 px-[41.5px] text-white normal-case flex lg:items-center w-[242px] border hover:cursor-pointer border-[#3E406A] hover:bg-[#3E406A] hover:border-none"
                >
                  <img src={PlayStoreLogo} alt={PlayStoreLogo} height={20} className=""/>
                  <span>Download for Android</span>
                </Button>
              </Link>
            </div>
          </div>

          {/*Divider Line*/}
          <div className="bg-[#3E406A] w-full h-[1px]"/>

          <div className="w-full flex justify-between items-center">
            <Typography className="text-[#53557A] font-normal font-clash leading-[19.68px] tracking-two text-base">©2024 OrnaMap. All rights reserved.</Typography>

            {/*Socials*/}
            <div className="flex justify-between gap-12">
              {/*Twitter*/}
              <Link to="https://x.com/ornamapapp" target="_blank" className="flex gap-2 items-center">
                <img
                  src={TwitterIcon}
                  alt={TwitterIcon}
                  width={19.57}
                  height={20}
                />

                <Typography className="text-[#53557A] font-clash font-medium text-base leading-[16px] tracking-two">X
                  (Twitter)</Typography>
              </Link>

              {/*Tiktok*/}
              <Link to="https://www.tiktok.com/@ornamap" target="_blank" className="flex gap-2 items-center">
                <img src={TikTok} alt={TikTok} width={20} height={20}/>
                <Typography
                  className="text-[#53557A] font-clash font-medium text-base leading-[16px] tracking-two">TikTok</Typography>
              </Link>

              {/*Facebook*/}
              <Link to="https://www.facebook.com/ornamap" target="_blank" className="flex gap-2 items-center">
                <img src={Facebook} alt={Facebook} width={20} height={20}/>
                <Typography
                  className="text-[#53557A] font-clash font-medium text-base leading-[16px] tracking-two">Facebook</Typography>
              </Link>

              {/*LinkedIn*/}
              <Link to="https://www.linkedin.com/company/ornamap" target="_blank" className="flex gap-2 items-center">
                <img src={LinkedIn} alt={LinkedIn} width={20} height={20}/>
                <Typography
                  className="text-[#53557A] font-clash font-medium text-base leading-[16px] tracking-two">LinkedIn</Typography>
              </Link>

              {/*Instagram*/}
              <Link to="https://www.instagram.com/ornamap" target="_blank" className="flex gap-2 items-center">
                <img src={Instagram} alt={Instagram} width={20} height={20}/>
                <Typography
                  className="text-[#53557A] font-clash font-medium text-base leading-[16px] tracking-two">Instagram</Typography>
              </Link>
            </div>
          </div>
        </div>

        <Typography className="text-[#3E406A] text-[332px] leading-[408.36px] tracking-two font-semibold font-clash">OrnaMap</Typography>
      </div>
    </Fragment>
  )
}

export default Footer