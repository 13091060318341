import {Fragment} from "react";
import {Button, Typography} from "@material-tailwind/react";

import ExperienceUsers from "../../assets/img/homepage/Experience-Users.png";
import ExperienceScreen from "../../assets/img/homepage/Experience-Screen.png"
import AppleLogo from "../../assets/svg/homepage/Apple-Logo.svg";
import PlayStoreLogo from "../../assets/svg/homepage/PlayStore-Logo.svg";
import {handleDownloadNowClick} from "../../utilities";

const YourExperience = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-full flex flex-col gap-4 items-center justify-center my-20 max-w-7xl">
          <div className="h-[570px] w-full bg-[#F2F2F5] rounded-[30px] flex gap-16 overflow-hidden">
            <div className="flex flex-col w-1/2 px-16 pt-16 gap-12">
              <Typography
                className="text-[#080912] font-clash font-semibold text-[40px] leading-[40px] tracking-two">
                OrnaMap makes your commuting experience smoother.
              </Typography>

              <div className="flex gap-4 items-center w-[512px] overflow-hidden">
                <div className="h-[85px] w-[300px]">
                  <img src={ExperienceUsers} alt={ExperienceUsers}/>
                </div>

                <Typography className="text-[#53557A] leading-[24px] text-2xl font-medium font-clash tracking-two">
                  35K+ Users
                </Typography>
              </div>

              <div className="flex items-center gap-x-1 mt-24">
                <Button
                  size="sm"
                  className="hidden bg-[#282B59] rounded-full p-4 text-white normal-case lg:flex lg:items-center"
                  onClick={handleDownloadNowClick}
                >
                  <div className="flex items-center mr-2 gap-2">
                    <img src={AppleLogo} alt={AppleLogo} height={20}/>
                    <div className="min-h-[20px] border-l border-white w-[1px]"/>
                    <img src={PlayStoreLogo} alt={PlayStoreLogo} height={20}/>
                  </div>
                  <span>Download App Now</span>
                </Button>
              </div>
            </div>

            <div className="w-1/2 max-w-[385px] h-full pt-16 flex">
              <img src={ExperienceScreen} alt={ExperienceScreen}/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default YourExperience;
