import {Fragment} from "react";
import {Card, Typography} from "@material-tailwind/react";
import HeroExploreSvg from "../../assets/svg/homepage/HeroExplore.svg";
import HeroScreenPhone from "../../assets/img/aboutUs/OrnaMapDeviceHome.png";

const AboutUsHero = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center">
        <div className="max-w-7xl w-full flex flex-col">
          {/*About Intro*/}
          <div className="w-full flex flex-col items-center justify-center gap-3">
            <Typography
              variant="h1"
              color="blue-gray"
              className="font-semibold text-textPrimary text-[68px] leading-[68px] tracking-two font-clash"
            >
              Our Mission
            </Typography>

            <Typography
              variant="paragraph"
              color="blue-gray"
              className="font-medium text-center text-[24px] leading-[40.32px] tracking-two text-[#53557A] font-clash max-w-[691px]"
            >
              Our mission is to empower seamless and efficient travel experiences
              for everyone by providing innovative, real-time transit navigation
              solutions.
            </Typography>
          </div>

          {/*AboutHero*/}
          <div className="w-full grid grid-cols-1 gap-[68px] lg:grid-cols-[40%_60%] lg:mt-[148px]">
            <Card
              className="w-full h-[522px] overflow-hidden pt-10 px-5 pb-0 rounded-none rounded-t-2xl rounded-l-2xl bg-[#EAEAEE] flex flex-col items-center gap-4 justify-start shadow-none"
            >
              <div className="w-full mb-0 h-3/12">
                <img
                  src={HeroExploreSvg}
                  alt={HeroExploreSvg}
                  className="w-full h-full"
                />
              </div>

              <div className="w-full mt-0 px-20 flex justify-center h-9/12">
                <div className="w-full h-[361px]">
                  <img
                    src={HeroScreenPhone}
                    alt={HeroScreenPhone}
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            </Card>

            <div className="w-full flex flex-col items-baseline justify-start gap-8">
              <Typography variant="h2" className="font-clash text-start font-semibold leading-[40px] tracking-two text-[#080912] text-[40px]">
                About Us
              </Typography>

              <Typography variant="h2" className="font-clash text-start font-medium leading-[40.32px] tracking-two text-[#53557A] text-2xl max-w-[667px]">
                OrnaMap is a mobile app transforming transit navigation in Nigeria by providing real-time access to train, bus and ferry schedules, fare information, route suggestions, and navigation for key services like the Lagos Blue Line Metro, Red Line Train, Lagos Ferry Service, and Lagos-Ibadan Train. Since January 2021, OrnaMap has simplified travel planning, empowering thousands to make informed, confident journey decisions.
                <br/>
                <br/>
                <br/>
                With the added convenience of Cowry Card top-ups directly in the app, users can enjoy seamless, cashless commutes across trains, ferries, and buses. OrnaMap is committed to making travel easy, accessible, and reliable for all.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutUsHero;
