import {Fragment, useState} from "react";
import {Button, Typography} from "@material-tailwind/react";
import FaqItem from "./FagItem";

const AboutOrnaMap = () => {
  const [openItems, setOpenItems] = useState(new Set(['what-is-ornamap']));

  const faqItems = [
    {
      id: 'what-is-ornamap',
      question: 'What is OrnaMap?',
      answer: 'OrnaMap is a mobile app designed to help you plan your train and ferry trips in Nigeria. The app allows you to search for train and ferry schedules, find suggested means of transport to the train station or harbor, and even book your trip in advance. With OrnaMap, you can easily plan your journey and enjoy the scenic views on your travels.'
    },
    {
      id: 'cowry-card-funding',
      question: 'How can I fund my Cowry card using OrnaMap?',
      answer: 'This feature is not currently available in OrnaMap. Please check the official Cowry card channels for funding options.'
    },
    {
      id: 'real-time-schedules',
      question: 'Does OrnaMap show real-time schedules for buses, ferries, and trains?',
      answer: 'OrnaMap provides schedule information for trains and ferries. Please check the app for the most up-to-date scheduling information for your route.'
    }
  ];

  const toggleItem = (itemId: string) => {
    const newOpenItems = new Set(openItems);
    if (newOpenItems.has(itemId)) {
      newOpenItems.delete(itemId);
    } else {
      newOpenItems.add(itemId);
    }
    setOpenItems(newOpenItems);
  };

  return (
    <Fragment>
      <div className="w-full bg-gradient-to-b from-[#12A6284D] to-[#FFFFFF4D] h-[700px] flex justify-center py-16">
        <div className="w-full h-full max-w-7xl flex items-center justify-center gap-16">
          <div className="h-full flex flex-col justify-start w-1/3 max-w-[370px] gap-8">
            <div className="bg-white px-5 py-3 rounded-full h-[46px] w-fit">
              <Typography variant="small" className="font-clash font-normal text-lg text-textPrimary leading-[22.14px] tracking-two">
                Got Questions?
              </Typography>
            </div>

            <Typography variant="h2" className="text-[#080912] font-clash font-semibold text-[40px] leading-[40px] tracking-two">
              Some of the things you may want to know
            </Typography>

            <Typography className="font-clash font-normal text-2xl leading-[29.52px] tracking-two">
              We’ve answered them so you don’t have to ask.
            </Typography>

            <Button className="font-clash bg-[#282B59] px-6 py-5 mt-2 rounded-full text-white font-medium leading-[19.68px] w-fit tracking-two text-base">See more FAQs</Button>
          </div>

          <div className="w-2/3 h-full">
            <div className="divide-y divide-gray-200">
              {faqItems.map((item) => (
                <FaqItem
                  key={item.id}
                  question={item.question}
                  answer={item.answer}
                  isOpen={openItems.has(item.id)}
                  onToggle={() => toggleItem(item.id)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AboutOrnaMap;
