import {Fragment} from "react";
import {Button, Card, Typography} from "@material-tailwind/react";
import AppleLogo from "../../assets/svg/homepage/Apple-Logo.svg";
import PlayStoreLogo from "../../assets/svg/homepage/PlayStore-Logo.svg";
import HeroExploreSvg from "../../assets/svg/homepage/HeroExplore.svg";
import HeroScreenPhone from "../../assets/svg/homepage/HomeScreenPhone.svg";
import {handleDownloadNowClick} from "../../utilities";

const HeroSection = () => {
  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center">
        <div className="max-w-7xl grid mb-20 grid-cols-1 gap-6 lg:grid-cols-2">
          {/*Plan Every Step of Your Journey*/}
          <Card
            className="w-full p-5 rounded-none rounded-t-2xl h-[720px] rounded-r-2xl bg-white flex flex-col items-center gap-6 justify-center shadow-none lg:p-10"
          >
            <Typography
              variant="h3"
              color="blue-gray"
              className="font-semibold text-textPrimary text-[68px] leading-[68px] tracking-two"
            >
              Plan every step of your journey
            </Typography>

            <Typography
              variant="paragraph"
              color="blue-gray"
              className="font-normal font-clash text-[#53557A] text-[24px] leading-[29.52px] tracking-two"
            >
              OrnaMap app provides real-time schedules, step-by-step directions, and seamless ticketing for trains,
              ferries, and buses.
            </Typography>

            <div className="w-full mt-6 flex items-start">
              <Button
                size="sm"
                className="hidden bg-[#282B59] rounded-full py-[16px] px-[24px] text-white normal-case lg:flex lg:items-center"
                onClick={handleDownloadNowClick}
              >
                <div className="flex items-center mr-2 gap-2">
                  <img src={AppleLogo} alt={AppleLogo} height={20}/>
                  <div className="min-h-[20px] border-l border-white w-[1px]"/>
                  <img src={PlayStoreLogo} alt={PlayStoreLogo} height={20}/>
                </div>
                <span className="font-medium font-clash text-[16px] tracking-two leading-[16px]">Download App Now</span>
              </Button>
            </div>
          </Card>

          {/*Travel Data*/}
          <Card
            className="w-full h-[720px] overflow-hidden pt-10 p-5 rounded-none rounded-t-2xl rounded-l-2xl bg-[#EAEAEE] flex flex-col items-center gap-4 justify-start shadow-none"
          >
            <div className="w-full mb-auto h-3/12">
              <img
                src={HeroExploreSvg}
                alt={HeroExploreSvg}
                className="w-full h-full"
              />
            </div>

            <div className="w-full mt-auto px-20 flex justify-center h-9/12">
              <div className="w-full h-full">
                <img
                  src={HeroScreenPhone}
                  alt={HeroScreenPhone}
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </Fragment>
  )
}

export default HeroSection;
