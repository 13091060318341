import React, { Fragment } from "react";
import {BsApple, BsGooglePlay} from "react-icons/bs";
import Text from "../global/Text";
import DiscoverImage from '../../assets/img/homepage/img-discover.png';

const DiscoverOrna = () => {
  return (
    <Fragment>
      <div className={`max-w-screen-xl flex flex-col items-center justify-center w-full p-3`}>
        <Text
          text={`Travel effortlessly with OrnaMap`}
          weight={700}
          className={`dark-large-text-typography lg:text-[48px] pb-[28px] lg:pb-0`}
        />

        <div className="flex flex-row w-full lg:w-[50%] max-w-[350px] justify-center my-4 lg:mt-8">
          <a
            typeof="button"
            target={`_blank`}
            href={`https://apps.apple.com/ng/app/ornamap/id6450780439`}
            className="min-w-[160px] text-black bg-white rounded-[7px] text-sm py-3 px-2 inline-flex items-center mr-2 lg:mr-auto lg:px-5 lg:py-1 store-download-shadow"
          >
            <BsApple color="#282B59" size={30} className={`mr-1 hidden lg:flex`}/>
            <BsApple color="#282B59" size={20} className={`mr-1 flex lg:hidden`}/>

            <div className="flex flex-col ml-2">
              <Text
                text="Available on"
                weight={400}
                className="text-primary text-[12px] leading-[16px] mb-1 lg:text-[14px] lg:leading-[22px] lg:mb-0"
              />

              <Text
                text="App Store"
                weight={700}
                className="text-[16px] text-primary leading-[12px] lg:text-[18px] lg:leading-[25px]"
              />
            </div>
          </a>

          <a
            typeof="button"
            target={`_blank`}
            href={`https://play.google.com/store/apps/details?id=com.ornamap.android`}
            className="min-w-[160px] text-black bg-white rounded-[7px] text-sm py-3 px-2 inline-flex items-center mr-2 lg:mr-auto lg:px-5 lg:py-1 store-download-shadow"
          >
            <BsGooglePlay color="#282B59" size={30} className={`mr-1 hidden lg:flex`}/>
            <BsGooglePlay color="#282B59" size={20} className={`mr-1 flex lg:hidden`}/>

            <div className="flex flex-col ml-2">
              <Text
                text="Available on"
                weight={400}
                className="text-primary text-[12px] leading-[16px] mb-1 lg:text-[14px] lg:leading-[22px] lg:mb-0"
              />

              <Text
                text="Play Store"
                weight={700}
                className="text-[16px] text-primary leading-[12px] lg:text-[18px] lg:leading-[25px]"
              />
            </div>
          </a>
        </div>

        <div className={`w-full flex items-center justify-center hidden lg:mt-[42px] mb-[26px] lg:flex z-10`}>
            <img alt={`Discover OrnaMap`} src={DiscoverImage} className={`h-[520px]`}/>
        </div>
      </div>
    </Fragment>
  )
}

export default DiscoverOrna;