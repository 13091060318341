import { Fragment, useEffect, useState } from "react";
import { Card, Typography } from "@material-tailwind/react";
import {ArrowRightIcon} from "@heroicons/react/24/solid";

interface Profile {
  id: number;
  name: string;
  profession: string;
  rating: number;
  review: string;
}

interface CurvedConnectorProps {
  startId: string;
  endId: string;
  isActive: boolean;
}

interface PathPoint {
  x: number;
  y: number;
}

const CurvedConnector  = ({ startId, endId, isActive }: CurvedConnectorProps) => {
  const [path, setPath] = useState<string>('');

  useEffect(() => {
    const updatePath = () => {
      const startEl = document.getElementById(startId);
      const endEl = document.getElementById(endId);

      if (!startEl || !endEl) return;

      const startRect = startEl.getBoundingClientRect();
      const endRect = endEl.getBoundingClientRect();

      // Get the parent container's position
      const containerRect = startEl.closest('.relative')?.getBoundingClientRect();
      if (!containerRect) return;

      // Calculate positions relative to the container
      const start: PathPoint = {
        x: startRect.right - containerRect.left,
        y: startRect.top - containerRect.top + startRect.height / 2
      };

      const end: PathPoint = {
        x: endRect.left - containerRect.left,
        y: endRect.top - containerRect.top + endRect.height / 2
      };

      const distance = end.x - start.x;
      const controlPoint1: PathPoint = {
        x: start.x + distance * 0.25,
        y: start.y - distance * 0.2
      };
      const controlPoint2: PathPoint = {
        x: start.x + distance * 0.75,
        y: end.y + distance * 0.2
      };

      const pathData = `
        M ${start.x} ${start.y}
        C ${controlPoint1.x} ${controlPoint1.y},
          ${controlPoint2.x} ${controlPoint2.y},
          ${end.x} ${end.y}
      `;

      setPath(pathData);
    };

    updatePath();
    window.addEventListener('resize', updatePath);
    return () => window.removeEventListener('resize', updatePath);
  }, [startId, endId]);

  return (
    <svg
      className="absolute top-0 left-0 w-full h-full"
      style={{
        zIndex: 0,
        pointerEvents: 'none',
      }}
    >
      <path
        d={path}
        fill="none"
        stroke={isActive ? "#000" : "#999"}
        strokeWidth={isActive ? "2" : "1.5"}
        style={{
          opacity: isActive ? 1 : 0.3,
          transition: 'all 0.3s ease'
        }}
      />
    </svg>
  );
};

const profiles: Profile[] = [
  {
    id: 1,
    name: "Olumide Babalola",
    profession: "Accountant",
    rating: 4.8,
    review: "As a daily commuter, I used to struggle with knowing when the next bus would arrive. Since I started using OrnaMap, everything's been so much easier! The real-time updates have been a lifesaver, especially on busy mornings when I need to get to work on time. Plus, I can top up my Cowry card directly in the app. No more standing in long queues!"
  },
  {
    id: 2,
    name: "Aisha Adedayo",
    profession: "Software Engineer",
    rating: 4.9,
    review: "Working in tech means I need reliable transportation to meet project deadlines. This service has transformed my daily commute. The interface is intuitive, and the real-time tracking feature is incredibly accurate. I can plan my journey down to the minute!"
  },
  {
    id: 3,
    name: "Chinedu Okeke",
    profession: "Marketing Director",
    rating: 4.7,
    review: "Managing multiple client meetings across Lagos requires precise timing. The journey planning feature has been invaluable for organizing my schedule. I can now provide accurate ETAs to clients and never miss an important presentation."
  },
  {
    id: 4,
    name: "Funmi Adeyemi",
    profession: "Healthcare Consultant",
    rating: 4.9,
    review: "In healthcare, punctuality is crucial. This app has made it possible for me to reliably schedule patient consultations across different hospitals. The real-time traffic updates help me avoid delays and provide better service to my patients."
  }
];

const ProfileCard = ({ profile, isSelected, onClick, id }: {
  profile: Profile;
  isSelected: boolean;
  onClick: () => void;
  id: string;
}) => (
  <div
    id={id}
    className={`w-full h-[95px] bg-[#EAEAEE] text-2xl leading-6 tracking-two font-clash rounded-lg flex justify-between items-center px-4 cursor-pointer transition-colors duration-200 ${
      isSelected ? 'bg-green-100 font-medium leading-[29.52px]' : 'hover:bg-gray-200'
    }`}
    onClick={onClick}
  >
    {profile.name}

    <ArrowRightIcon className="h-4 w-6 font-bold" />
  </div>
);

const DetailPanel = ({ profile }:{ profile: Profile }) => (
  <div
    id="details-panel"
    className="flex flex-col w-2/6 bg-[#EAEAEE] rounded-lg items-center justify-center px-4 h-[430px]"
  >
    <Typography className="w-full text-center font- text-2xl font-medium leading-8 font-clash text-textPrimary">
      {profile.name}
    </Typography>
    <Typography className="w-full text-center text-textPrimary text-lg tracking-two font-clash leading-[22.14px]">
      {profile.profession}
    </Typography>
  </div>
);

const ReviewPanel = ({ review }: { review: string }) => (
  <div className="w-[400px] h-[430px] bg-[#EAEAEE] rounded-lg p-6">
    <div>
      <svg className="w-8 h-8 text-gray-400 mb-4" fill="currentColor" viewBox="0 0 24 24">
        <path
          d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"/>
      </svg>
      <p className="text-gray-700 text-lg font-medium leading-[30/24px] tracking-two my-[32px]">{review}</p>
      <svg
        className="w-8 h-8 text-gray-400 ml-auto transform rotate-180"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"/>
      </svg>
    </div>
  </div>
);

const Testimonials = () => {
  const [selectedProfile, setSelectedProfile] = useState<Profile>(profiles[0]);
  const [activeId, setActiveId] = useState<string>('profile-1');

  return (
    <Fragment>
      <div className="w-full mx-auto flex justify-center">
        <div className="w-full flex flex-col gap-4 items-center justify-center my-20 max-w-7xl">
          <div className="bg-[#D0EDD4] rounded-full px-8 py-3">
            <Typography variant="paragraph" className="text-textPrimary font-[400] text-[18px]">
              Testimonials
            </Typography>
          </div>

          <Typography variant="h2"
                      className="text-textPrimary font-bold text-[40px] text-center max-w-[700px] font-clash">
            Word on the Street
          </Typography>

          <Card className="w-full px-0 mt-8 shadow-none bg-transparent">
            <div className="relative w-full overflow-visible">
              <div className="flex gap-16">
                {/* Left panel with profiles */}
                <div className="flex flex-col space-y-4 w-3/12">
                  {profiles.map((profile) => (
                    <ProfileCard
                      key={profile.id}
                      profile={profile}
                      isSelected={selectedProfile.id === profile.id}
                      onClick={() => {
                        setSelectedProfile(profile);
                        setActiveId(`profile-${profile.id}`);
                      }}
                      id={`profile-${profile.id}`}
                    />
                  ))}
                </div>

                {/* Right panel with details in a row */}
                <div className="flex items-center justify-center w-9/12">
                  <DetailPanel profile={selectedProfile}/>
                  <div className="w-12 h-1 bg-[#75757A]"/>
                  <ReviewPanel review={selectedProfile.review}/>
                </div>
              </div>

              {/* Render all connectors */}
              {profiles.map((profile) => (
                <CurvedConnector
                  key={profile.id}
                  startId={`profile-${profile.id}`}
                  endId="details-panel"
                  isActive={`profile-${profile.id}` === activeId}
                />
              ))}
            </div>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

export default Testimonials;
